// $grid-columns: 12;
// $grid-gutter: 24px;

$breakpoints: (
    xs: 360px,
    sm: 640px,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
    xxl: 1600px,
);

// $spaces: (
//     auto: auto,
//     0: 0,
//     8: 0.8rem,
//     16: 1.6rem,
//     24: 2.4rem,
//     32: 3.2rem,
//     40: 4rem,
//     48: 4.8rem,
//     56: 5.6em,
//     64: 6.4rem
// );

$colorBlack: #222;
$colorWhite: #fff;
$colorBlue: #231269;
$colorDarkBlue: #1A095F;
$colorGreen: #0BED73;
$colorDarkGreen: #0BA552;
