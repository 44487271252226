.button {
    position: relative;
    z-index: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 4.8rem;
    border-radius: 2.4rem;
    padding: .8rem 2.4rem;
    border: 0;
    outline: 0;
    background: linear-gradient(to bottom, $colorGreen, $colorDarkGreen);
    box-shadow: 0 .4rem 1.6rem rgba($colorBlack, .2);
    font-family: "Rubik", sans-serif;
    font-size: 1.4rem;
    color: $colorWhite;

    &:hover {
        background: linear-gradient(to top, $colorGreen, $colorDarkGreen);
        cursor: pointer;
    }

    @include media-query(sm) {
        font-size: 1.6rem;
    }
}