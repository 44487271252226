.footer {
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: $colorWhite;
    color: $colorBlack;

    &__layout {
        display: grid;
        gap: 2rem;
    }

    &__sub-layout {
        display: grid;
        gap: 2rem;
    }

    &__org {
        margin-top: 1.33em;

        &__list {
            display: flex;
            flex-wrap: wrap;
            gap: 3.2rem;
        }
    }

    &__nav {
        &__list {
            display: grid;
            gap: .8rem;
        }

        &__link {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__social-media {
        &__list {
            display: flex;
            flex-wrap: wrap;
            gap: 1.6rem;
        }
    }

    @include media-query(sm) {
        padding-top: 8rem;
        padding-bottom: 8rem;

        &__layout {
            grid-template-columns: repeat(3, 1fr);
            gap: 4rem;
        }
    }
}