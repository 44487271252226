body {
    background-color: $colorBlue;
    font-family: "Rubik", sans-serif;
    font-size: 1.4rem;
    line-height: 1.75em;
    color: $colorWhite;

    @include media-query(sm) {
        font-size: 1.6rem;
    }
}