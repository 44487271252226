@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text&family=Rubik:wght@400;500&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "DM Serif Text", serif;

    &:last-child {
        margin-bottom: 0;
    }

    span {
        display: block;
        font-family: "Rubik", sans-serif;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.75em;
    }

    @include media-query(sm) {
        span {
            font-size: 1.6rem;
        }
    }
}

h1 {
    font-size: 4.8rem;
    margin-bottom: calc(1.25em / 4.8);

    @include media-query(sm) {
        font-size: 6.4rem;
        margin-bottom: calc(1.25em / 6.4);
    }
}

h2 {
    font-size: 2.8rem;
    margin-bottom: calc(1.25em / 2.8);

    @include media-query(sm) {
        font-size: 3.2rem;
        margin-bottom: calc(1.25em / 3.2);
    }
}

h3 {
    font-size: 2rem;
    margin-bottom: calc(1.25em / 2);

    @include media-query(sm) {
        font-size: 2.4rem;
        margin-bottom: calc(1.25em / 2.4);
    }
}

p,
ol,
ul {
    margin-bottom: 1em;

    &:last-child {
        margin-bottom: 0;
    }
}

strong {
    font-weight: 500;
}

small {
    font-size: .875em;
    color: rgba($colorWhite, .66);
}