.grid {
    display: grid;
    gap: 2rem;
}

.field-group {
    @include media-query(sm) {
        display: grid;
        grid-template-columns: 18rem 1fr;
    }
}

input[type="text"],
input[type="email"],
input[type="number"],
select {
    width: 100%;
    border: 0;
    border-radius: 4px;
    min-height: 4.8rem;
    padding: .8rem 1.6rem;
    background-color: rgba($colorWhite, .1);
    font-family: "Rubik", sans-serif;
    color: $colorWhite;

    &:focus {
        outline: 0;
    }
}

select {
    &:hover {
        cursor: pointer;
    }
}

option {
    color: $colorBlack;
}

textarea {
    width: 100%;
    border: 0;
    border-radius: 4px;
    padding: .8rem 1.6rem;
    background-color: rgba($colorWhite, .1);
    font-family: "Rubik", sans-serif;
    color: $colorWhite;

    &:focus {
        outline: 0;
    }
}

.input-checkbox {
    display: block;
}

.field-validation-error {
    display: block;
    margin-top: .8rem;
    @extend small;
    line-height: 1.25em;
}