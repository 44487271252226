.hero {
    position: relative;
    z-index: 0;
    display: grid;
    align-items: center;
    width: 100%;
    min-height: 100dvh;
    padding-top: 4rem;
    padding-bottom: 4rem;

    &__body {
        width: 100%;
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    &__image {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            opacity: .33;
            mix-blend-mode: screen;
            object-fit: cover;
        }
    }

    &__location {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2.4rem;
        margin-top: 2.4rem;

        &__date {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 8rem;
            height: 12rem;
            background: linear-gradient(to bottom, $colorGreen, $colorDarkGreen);
            border-top-left-radius: 4rem;
            border-bottom-left-radius: 4rem;
            border-bottom-right-radius: 4rem;
            font-size: 2.8rem;
            color: $colorWhite;

            small {
                display: block;
                font-size: 1.8rem;
                color: $colorWhite;
            }
        }
    }

    @include media-query(sm) {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
}

.info {
    &__body {
        padding: 4rem;
    }

    @include media-query(sm) {
        &__layout {
            display: grid;
            align-items: center;
            justify-items: end;
            grid-template-columns: repeat(2, 1fr);
            min-height: 480px;
        }

        &__image {
            position: relative;
            width: 100%;
            height: 100%;

            img {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__body {
            max-width: 640px;
            padding: 8rem 8rem 8rem 3.2rem;
        }
    }
}

.timetable {
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: $colorDarkBlue;

    &__body {
        width: 100%;
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4rem;
        text-align: center;
    }

    &__overview {
        width: 100%;
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
    }

    &__list {
        display: grid;
        gap: 2rem;
    }

    &__item {
        &__time {
            font-weight: 500;
            color: $colorGreen;
        }
    }

    @include media-query(sm) {
        padding-top: 8rem;
        padding-bottom: 8rem;

        &__list {
            gap: 4rem;
        }

        &__item {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 2rem;
        }
    }
}

.speakers {
    padding-top: 4rem;
    padding-bottom: 4rem;

    &__body {
        width: 100%;
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4rem;
        text-align: center;
    }

    &__overview {
        width: 100%;
        max-width: 960px;
        margin-left: auto;
        margin-right: auto;
    }

    &__list {
        display: grid;
        gap: 4rem;
    }

    &__item {
        display: grid;
        justify-items: center;
        gap: 2rem;

        &__image {
            position: relative;
            width: 12rem;
            aspect-ratio: 1;
            background: linear-gradient(to bottom, $colorGreen, $colorDarkGreen);
            border-top-left-radius: 6rem;
            border-bottom-left-radius: 6rem;
            border-bottom-right-radius: 6rem;
            box-shadow: 0 .4rem 1.6rem rgba($colorBlack, .2);

            img {
                position: absolute;
                bottom: 0;
                left: 0;
                border-bottom-left-radius: 6rem;
                border-bottom-right-radius: 6rem;
            }
        }
    }

    @include media-query(sm) {
        padding-top: 8rem;
        padding-bottom: 8rem;

        &__item {
            grid-template-columns: 12rem 1fr;
            justify-items: start;
            align-items: center;
            padding-top: 2rem;
            gap: 4rem;
        }
    }
}

.speaker {
    &__details {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0 1.6rem;
    }

    &__linkedin {
        display: inline-block;
        vertical-align: middle;

        img {
            display: block;
            width: 1.6rem;
            height: 1.6rem;
        }
    }
}

.location {
    background-color: $colorDarkBlue;

    &__body {
        padding: 4rem;
    }

    @include media-query(sm) {
        &__layout {
            display: grid;
            align-items: center;
            grid-template-columns: repeat(2, 1fr);
            min-height: 480px;
        }

        &__image {
            position: relative;
            width: 100%;
            height: 100%;

            img {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__body {
            max-width: 640px;
            padding: 8rem 3.2rem 8rem 8rem;
        }
    }
}

.subscribe {
    padding-top: 4rem;
    padding-bottom: 4rem;

    &__body {
        width: 100%;
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4rem;
        text-align: center;
    }

    &__form {
        width: 100%;
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
    }

    @include media-query(sm) {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
}