.header {
    position: sticky;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;

    &__layout {
        display: grid;
        grid-template-columns: 4.8rem 1fr 4.8rem;
        grid-template-rows: repeat(2, auto);
        grid-template-areas:
            "logo menu toggle"
            "dropdown dropdown dropdown";
        padding: 2.4rem;
        gap: 0 2.4rem;
        transition:
            background-color .25s ease-in-out,
            padding .25s ease-in-out;

        .menu-is-active & {
            background-color: $colorDarkBlue;
            box-shadow: 0 .4rem 1.6rem rgba($colorBlack, .2);
        }
    }

    &__logo {
        grid-area: logo;
    }

    &__nav {
        display: none;
        grid-area: dropdown;

        .menu-is-active & {
            display: block;
        }

        &__list {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: .8rem;
        }

        &__link {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 2.4rem;
            height: 4.8rem;
            border-radius: 2.4rem;

            &:hover {
                background-color: $colorBlue;
            }
        }

        &__toggle {
            grid-area: toggle;
            display: block;
            position: relative;
            width: 4.8rem;
            height: 4.8rem;
            border-radius: 2.4rem;
            background-color: $colorBlue;
            box-shadow: 0 .4rem 1.6rem rgba($colorBlack, .2);

            span {
                display: block;
                width: 2rem;
                height: .2rem;
                background-color: $colorWhite;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -.1rem;
                margin-left: -1rem;

                &:nth-child(1) {
                    margin-top: -.8rem;

                    .menu-is-active & {
                        margin-top: -.1rem;
                        transform: rotate(45deg);
                    }
                }

                &:nth-child(2) {
                    .menu-is-active & {
                        display: none;
                    }
                }

                &:nth-child(3) {
                    margin-top: .6rem;

                    .menu-is-active & {
                        margin-top: -.1rem;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }

    @include media-query(sm) {
        &__layout {
            grid-template-rows: auto;
            align-items: center;
            justify-items: center;

            .is-sticky:not(.menu-is-active) & {
                background-color: $colorDarkBlue;
                box-shadow: 0 .4rem 1.6rem rgba($colorBlack, .2);
            }

            .menu-is-active:not(.is-sticky) & {
                background-color: transparent;
                box-shadow: none;
            }
        }

        &__nav {
            display: block;
            grid-area: menu;

            &__list {
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;

            }

            &__toggle {
                display: none;
            }
        }
    }
}